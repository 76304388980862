import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Livret Display';
  src: url("/fonts/TTLivretDisplayLight.woff") format("woff");
}
@font-face {
  font-family: 'Norms Pro';
  src: url("/fonts/TTNormsProRegular.woff") format("woff");
}
    *{
        margin: 0;
        padding: 0;
        font-family: 'Norms Pro', sans-serif;
        color: #31323A;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
        border: none;
       
        @media (min-width: 1440px) {
            /* font-size: 0.0695vw; */

            }
    }

    /* html{scroll-behavior: smooth;} */
    ::selection {
        color: #DBBDB6;
        background: #000000;
;
}
html {width: 100%;  
    } /* Force 'html' to be viewport width */

 body{
     overflow-x: hidden;
     background-color: #DBBDB6;
     width: 100vw;
     font-size:  16rem ; 

    

 }

/* body.dark{
    --bg: #0A0A0A;
     --rest: #f5f5f5; 
     background-color: var(--bg);
}

 body.white {
    --bg: #f5f5f5; 
    --rest: #0A0A0A;
    background-color: var(--bg);
 } */
 a, code, h1{
    font-size: 16rem;
 }
 p, span, input{
     font-size: 16rem;
 }
 input::-webkit-input-placeholder {
    font-size: 16rem;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;

    
        
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
